import React, { useEffect, useRef, useState } from "react"
import * as styles from './besichtigungsschein.module.scss';

import * as axios from "axios"
import { getStrapiUrl } from "../../helper/uri"
import { Previewer } from "pagedjs";

const Besichtigungsschein = () => {

  const [ immo, setImmo ] = useState(null);
  const [ contact, setContact ] = useState(null);
  const contactsRef = useRef();

  const [ error, setError ] = useState();

  const templateRef = useRef(null);
  const renderRef = useRef(null);

  const previewAction = (style) => {

    const previewCSS = ["/expose-reset.css"];
    previewCSS.push("/expose-reset-detail.css");

    const previewer = new Previewer();
    previewer
      .preview(
        templateRef.current.innerHTML,
        previewCSS,
        renderRef.current
      )
      .then(flow => {
        console.log("preview rendered, total pages", flow.total, {flow});
      });
  };

  const resetPreview = (style) => {
    renderRef.current.innerHtml = '';
    renderRef.current.innerText = '';

    setTimeout(
      () => {
        previewAction(style);
      },
      0
    );
  }

  useEffect(
    () => {
      const action = async () => {
        try {
          let res = null

          const urlParams = new URLSearchParams(window.location.search);
          const uuid = urlParams.get("uuid");

          if (uuid) {
            res = await axios.default.get(getStrapiUrl() + "/immos?uuid=" + uuid);
          }

          if (Array.isArray(res.data)) {
            setImmo(res.data[0]);
          } else {
            setImmo(res.data);
          }

          const contactsRes = await axios.default.get(getStrapiUrl() + "/immo-contacts");
          contactsRef.current = contactsRes.data;

          let kontaktpersonMail;

          if (Array.isArray(res.data)) {
            kontaktpersonMail = res.data[0].kontaktperson?.email_direkt?.toLowerCase();
          } else {
            kontaktpersonMail = res.data.kontaktperson?.email_direkt?.toLowerCase();
          }

          const immoContact = contactsRes.data.find(f => f.mail.toLowerCase() === kontaktpersonMail);
          setContact(immoContact);

        } catch(err) {
          console.log('*** error fetching object ', err);
          setError(err);
        }

        setTimeout(
          () => {
            previewAction("detail");
          },
          500
        );

      };

      action();
    },
    [ ]
  );


  return <>

    {/*<Helmet title={"Besichtigungsschein"}>*/}
    {/*  <meta name={"robot"} content={"no-index"} />*/}
    {/*</Helmet>*/}


    <div ref={templateRef} className={"d-none "}>

      { immo &&
      <>
        <h1>Besichtigungsschein</h1>

        <p>
          { immo.title }<br />
          { immo.geo.strasse } {immo.geo.hausnummer}<br />
          { immo.geo?.plz } { immo.geo?.ort }
        </p>

        <p className="mt-5">
          Der Interessent bestätigt, dass ihm das heute ... Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi at beatae commodi consequuntur cumque dolorem eaque, eligendi eos harum iste laudantium nam odio praesentium quasi quibusdam quod, repellendus sapiente sunt tempora voluptatem. At itaque laboriosam magni nihil quasi sequi totam vero? Aliquam cupiditate esse id illo in nam nemo quod.
        </p>

        <div className="mt-5">
          <p>
          Name(n)/Firma: __________________________________________________
          </p>

          <p className={"mt-3"}>
          Anschrift: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            __________________________________________________
          </p>

          <p className={"mt-3"}>
            Telefon: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            _______________________
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            E-Mail: ______________________________
          </p>

          <p className={"mt-5"}>
            Der Interessent ist Konsument:
            &nbsp;&nbsp;&nbsp;
            _____ Ja&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;_____ Nein
          </p>

          <p className={"mt-5"}>
            Ort/Datum: ________________________ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Unterschrift: __________________________________
          </p>

        </div>

      </>
      }

      {error &&
      <>
        <p>
          <b>Fehler: </b>
          { JSON.stringify(error) }
        </p>
      </>
      }

    </div>

    <div ref={renderRef}/>

  </>;

}

export default Besichtigungsschein;
